<template>
    <div class="left-container">
        <div class="top-tool">
            <el-button type="primary" plain size="small" @click="addBtnClick">新增</el-button>
            <el-button type="primary" plain size="small" @click="sortBtnClick">排序</el-button>
        </div>
        <div class="page-tree">
            <el-tree
                ref="pageTreeRef"
                :data="pageOptions"
                :props="defaultProps"
                :highlight-current="true"
                node-key="value"
                @node-click="handlePageClick"
                :render-content="renderContent"
            ></el-tree>
        </div>
        <div class="page-components-container">
            <el-collapse v-model="activeNames" @change="handleCollapseChange">
                <el-collapse-item title="元素" name="pageComponents">
                    <CurrentPageComponents :maxH="maxComponentsH" />
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>
<script>
import CurrentPageComponents from "@/components/CurrentPageComponents"; //选择页所使用的组件
import { mapState } from 'vuex';

export default {
    props: ['currentPage', 'pageOptions'],
    data() {
        return {
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            activeNames: ['pageComponents'],
            maxComponentsH: null
        };
    },
    computed: {
        ...mapState(["componentData"])
    },
    components: {
        CurrentPageComponents
    },
    watch: {
        currentPage: {
            handler: function(value) {
                if (value) {
                    this.$nextTick(() => {
                        this.$refs.pageTreeRef.setCurrentKey(value + '');
                        this.setPageMaxH();
                    });
                }
            },
            immediate: true
        },
        componentData: function() {
            this.$nextTick(() => {
                this.setPageMaxH();
            });
        },
    },
    mounted() {
        this.setCompMaxH();
    },
    methods: {
        setPageMaxH() {
            var compElement = document.getElementsByClassName('page-components-container')[0];
            var pageElement = document.getElementsByClassName('page-tree')[0];
            var maxH = pageElement.parentElement.clientHeight - 42 - compElement.clientHeight;
            pageElement.style.maxHeight = maxH + 'px';
        },
        addBtnClick() {
            this.$emit('addPage');
            this.$nextTick(() => {
                var treeElement = document.getElementsByClassName('page-tree')[0];
                let endT = treeElement.scrollHeight - treeElement.clientHeight;
                document.getElementsByClassName('page-tree')[0].scrollTop = endT;
            })
        },
        sortBtnClick() {
            this.$emit('sortPage');
        },
        setCompMaxH() {
            this.$nextTick(() => {
                var height = document.getElementsByClassName('page-components-container')[0].parentElement.clientHeight;
                this.maxComponentsH = height / 2 - 42;
            })
        },
        handleCollapseChange(val) {
            this.activeNames = val;
            if (val.length === 0) {
                var pageElement = document.getElementsByClassName('page-tree')[0];
                pageElement.style.maxHeight = 'inherit';
            }
            setTimeout(() => {
                this.$nextTick(() => {
                    this.setPageMaxH();
                });
            }, 300)
        },
        handlePageClick(data) {
            if (data.value !== this.currentPage) {
                this.$refs.pageTreeRef.setCurrentKey(null);
                this.$emit('pageSelect', data.value)
            }
        },
        renderContent(h, { node, data, store }) {
            return (
                <span class="page-tree-node">
                    <span>{node.label}</span>
                    <span class="page-tree-node-right">
                        <el-button size="mini" type="text" on-click={ (e) => {
                            e.stopPropagation();
                            this.$emit('deletePage', data.value)
                            this.$nextTick(() => {
                                this.$refs.pageTreeRef.setCurrentKey(this.currentPage + '')
                            })
                        }}>删除</el-button>
                    </span>
                </span>);
        }
    }
};
</script>
<style lang="scss">
.page-tree {
    .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color: #bdd9f9;
    }
    .el-tree-node__content {
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        .page-tree-node {
            flex-grow: 1;
            padding-right: 15px;
        }
        .page-tree-node-right {
            float: right;
        }
    }
}
.page-components-container {
    .el-collapse-item__header {
        padding-left: 20px;
        height: 40px;
        line-height: 40px;
    }
    .el-collapse-item__content {
        padding-bottom: 0px;
    }
}
</style>
<style lang="scss" scoped>
.left-container {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    .top-tool {
        padding: 0px 10px 10px;
    }
    .page-tree {
        flex-grow: 1;
        overflow: auto;
    }
    .page-components-container {
        max-height: 50%;
    }
}
</style>
