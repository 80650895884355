<!-- TODO: 这个页面后续将用 JSX 重构 -->
<template>
  <div class="attr-list">
    <!-- 位置属性 -->
    <div class="locationInfo" v-if="attrsCategories.positionInfoOptions">
      <div class="divider">位置属性</div>
      <div
        class="info"
        v-for="l in attrsCategories.positionInfoOptions"
        :key="l.key"
      >
        <div class="label">
          {{ l.label }}:
        </div>
        <el-input-number
          :disabled="isFormDisabled || l.disabled"
          :controls="false"
          v-model.number="curComponent.style[l.key]"
          :precision="0"
          :min="0"
          @change="inputValChange(l.key)"
        />
        <span class="attr-error-tip">{{errorMsg(l.key) || ''}}</span>
      </div>
    </div>
    <!-- 组件属性 -->
    <div class="locationInfo" v-if="attrsCategories.componentAttrs">
      <div class="divider">组件属性</div>
      <template v-for="l in attrsCategories.componentAttrs">
        <!-- <div
          v-if="!(l.key === 'alias' && !curComponent.style.kqds)"
          :key="l.key"
          :class="['info', twoLinesInfoKeys.includes(l.key) && 'twoLinesInfo']"
        > -->
        <div
          :key="l.key"
          :class="['info', twoLinesInfoKeys.includes(l.key) && 'twoLinesInfo', l.type === 'resultRadio' && 'resultRadioInfo']"
          v-if="itemIsShow(l)"
        >
          <div class="label">
            <template v-if="l.key === 'rtspAdressGroups'">
              <el-popover
                placement="left-end"
                width="400"
                trigger="click">
                <div>
                  <div>本例以海康威视为例</div>
                  <div>rtsp://admin:12345@192.168.1.64:554/ch1/sub/av stream</div>
                  <div>【rtsp://】:rtsp协议</div>
                  <div>【admin】:登录账号，默认值为:admin</div>
                  <div>【12345】:登录密码，默认值为:12345</div>
                  <div>【192.168.1.64】:摄像机IP地址，默认值为:192.168.1.64</div>
                  <div>【554】:RTSP端口号</div>
                  <div>【/ch1/sub/av stream】:RTSP取流地址</div>
                </div>
                <el-button type="text" slot="reference"><i class="el-icon-question"></i></el-button>
              </el-popover>
            </template>
            {{ l.label }}:
          </div>
          <UploadArea
            :src="curComponent.style[l.key]"
            v-if="uploadImageKeys.includes(l.key)"
            :handler="
              () => {
                showImagesResourceModal(l.key);
              }
            "
            :deleteHandler="
              () => {
                resetImageDefaultSrc(l.key);
              }
            "
          />
          <!--数字输入框 -->
          <!-- <el-input
            v-if="numberInputeKeys.includes(l.key)"
            :disabled="isFormDisabled"
            v-model.number="curComponent.style[l.key]"
            :type="textInputKeys.includes(l.key) ? 'text' : 'number'"
          /> -->
          <el-input-number
            v-if="numberInputeKeys.includes(l.key)"
            :disabled="isFormDisabled"
            :controls="false"
            :min="limitVal(l.key, 'min')"
            :precision="limitVal(l.key, 'precision')"
            v-model.number="curComponent.style[l.key]"
            @change="inputValChange(l.key)"
          />
          <span v-if="l.key === 'btnLoadingTime'" style="margin-left: 5px;">秒</span>

          <!-- 文本输入框 -->
          <el-input
            v-if="textInputKeys.includes(l.key)"
            :disabled="isFormDisabled"
            v-model="curComponent.style[l.key]"
            :maxlength="limitVal(l.key, 'maxlength')"
            :placeholder="l.placeholder"
            :show-word-limit="true"
            type="text"
            @change="inputValChange(l.key)"
          />
          <span class="attr-error-tip" :style="arrAttrKeys.includes(l.key) ? {top: '25px',left: '0px'} : {}">{{errorMsg(l.key) || ''}}</span>

          <!-- textArea文本 -->
          <el-input
            v-if="l.key == 'inputContent'"
            :disabled="isFormDisabled"
            v-model="curComponent.propValue"
            type="textarea"
          />
          <el-input
            v-if="l.key === 'btnPressName'"
            :disabled="isFormDisabled"
            v-model="curComponent.style[l.key]"
            type="textarea"
          />
          <el-radio-group v-if="typeKeys.includes(l.key)" v-model="curComponent.style[l.key]">
            <el-radio v-for="item in l.options" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
          <!-- 颜色选择 -->
          <el-color-picker
            v-if="colorSelectKeys.includes(l.key)"
            v-model="curComponent.style[l.key]"
            :disabled="isFormDisabled"
          />
          <!-- 下拉选择框 -->
          <el-select
            v-if="selectFormKeys.includes(l.key)"
            v-model="curComponent.style[l.key]"
            placeholder="请选择"
            @change="(val) => selectChange(l, val)"
          >
            <el-option
              v-for="o in l.options"
              :key="o.id"
              :label="o.text"
              :value="o.id"
            >
            </el-option>
          </el-select>

          <!-- 开关 -->
          <el-switch
            v-if="switchFormKeys.includes(l.key)"
            v-model="curComponent.style[l.key]"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>

          <!-- rtsp流的组件 -->
          <div class="rtspAdressGroups" v-if="l.key == 'rtspAdressGroups'">
            <el-button type="primary" class="addGroup" @click="addRtspAdressGroup"
              >添加</el-button
            >
            <div
              class="rtspAdressGroup"
              v-for="g in curComponent.style.rtspAdressGroups"
              :key="g.id"
            >
              <el-input v-model="g.name" placeholder="名称"></el-input>
              <el-input
                v-model="g.rtspAdress"
                placeholder="rtsp流地址"
              ></el-input>
              <i
                class="el-icon-delete deleteGroup"
                @click="deleteGroup(g.id)"
              ></i>
            </div>
          </div>

          <!-- 视频组件 -->
          <div class="rtspAdressGroups" v-if="l.key == 'videoNameGroups'">
            <el-button type="primary" class="addGroup" @click="addVideoNameGroup"
              >添加</el-button
            >
            <div
              class="rtspAdressGroup"
              v-for="g in curComponent.style.videoNameGroups"
              :key="g.id"
            >
              <el-input v-model="g.name" placeholder="文件名称" @change="inputValChange(l.key)"></el-input>
              <i
                class="el-icon-delete deleteGroup"
                @click="deleteVideoGroup(g.id)"
              ></i>
            </div>
          </div>

          <!-- 文档组件 -->
          <div class="rtspAdressGroups" v-if="l.key == 'documentNameGroups'">
            <el-button type="primary" class="addGroup" @click="addDocumentNameGroup"
              >添加</el-button
            >
            <div
              class="rtspAdressGroup"
              v-for="g in curComponent.style.documentNameGroups"
              :key="g.id"
            >
              <el-input v-model="g.name" placeholder="文件名称" @change="inputValChange(l.key)"></el-input>
              <i
                class="el-icon-delete deleteGroup"
                @click="deleteDocumentGroup(g.id)"
              ></i>
            </div>
          </div>

          <!-- 复选框 -->
          <el-checkbox
            v-if="checkboxKeys.includes(l.key)"
            v-model="curComponent.style[l.key]"
          />
          <el-slider
            v-if="l.key === 'opacity'"
            v-model="curComponent.style[l.key]"
            :max="1"
            :step="0.1"
            style="width: 100%;"
          >
          </el-slider>
          <!-- 只有图片组件使用  资源库选图片 -->
          <UploadArea
            v-if="l.key == 'imageResource'"
            :handler="
              () => {
                showImagesResourceModal('imageResource');
              }
            "
            :deleteHandler="
              () => {
                resetImageDefaultSrc('imageResource');
              }
            "
            :src="`url(${curComponent.propValue.file_down_path})`"
          />

          <!-- 轮播图的添加资源 -->
          <div class="carouselRe" v-if="l.key == 'carouselPics'">
            <el-button
              icon="el-icon-plus"
              type="primary"
              v-if="!curComponent.style.carouselPics.length"
              @click="showCarouselModal"
              >添加资源</el-button
            >
            <div class="resourceInfo" v-else>
              <span>已选{{ curComponent.style.carouselPics.length }}个资源</span>
              <span class="icons">
                <i class="el-icon-edit-outline" @click="showCarouselModal"></i>
                <i class="el-icon-delete" @click="deleteCarouselPics"></i>
              </span>
            </div>
          </div>

          <div v-else-if="l.type === 'resultRadio'" class="result-radio-attr">
              <el-radio-group v-model="curComponent.style[l.key]">
                  <el-radio label="color">颜色</el-radio>
                  <el-radio label="icon">图标</el-radio>
              </el-radio-group>
              <div class="result-radio-attr-div">
                  <el-color-picker
                      v-if="curComponent.style[l.key] === 'color'"
                      v-model="curComponent.style[l.colorKey]"
                  />
                  <UploadArea
                      v-if="curComponent.style[l.key] === 'icon'"
                      :handler="
                      () => {
                          showImagesResourceModal(l.iconKey);
                      }
                      "
                      :deleteHandler="
                      () => {
                          resetImageDefaultSrc(l.iconKey);
                      }
                      "
                      :src="`url(${curComponent.style[l.iconKey]})`"
                  />
              </div>
          </div>
        </div>
      </template>
      
    </div>

    <!-- 图片资源的modal -->
    <ImagesResourceModal
      ref="imagesResourceModal"
      :modalOperationKey="modalOperationKey"
      :setCarouselPics="setCarouselPics"
      @confirm="imageModalConfirm"
    />

    <!-- 多图轮播已选择modal -->
    <CarouselSeletedPicModal
      ref="carouselSeletedPicModal"
      :showImagesResourceModal="showImagesResourceModal"
    />
  </div>
</template>

<script>
import {
  COMPONENT_ATTRS_CONFIG,
  NO_MATCH_COMPONENT_ATTRS,
  ATTRS_VALIDATE_MAP
} from "@/utils/attrNameData";
import UploadArea from "@/components/UploadArea";
import ImagesResourceModal from "@/components/ImagesResourceModal";
import CarouselSeletedPicModal from "@/components/CarouselSeletedPicModal";
import eventBus from '@/utils/eventBus'
import { getCurComponentsInd } from '@/utils/utils';

import { mapState } from "vuex";

export default {
  components: {
    UploadArea,
    ImagesResourceModal,
    CarouselSeletedPicModal,
  },
  data() {
    return {
      twoLinesInfoKeys: ["rtspAdressGroups", "videoNameGroups", "documentNameGroups"],
      excludes: ["Picture", "Group", "Back"], // 这些组件不显示内容
      attrsCategories: {},
      selectKey: ["textAlign", "borderStyle", "verticalAlign"],
      attrNameData: {},
      textInputKeys: [
        "alias",
        "pageName",
        "city",
        // "btnPressName",
        "buttonName",
        "url"
      ],
      arrAttrKeys: [
        'documentNameGroups',
        'videoNameGroups'
      ],
      numberInputeKeys: [
        "fontSize",
        "borderRadius",
        "pagesizeWidth",
        "pagesizeHeight",
        "interval",
        'btnLoadingTime',
        'circleSize',
        'trackSize'
      ],
      switchFormKeys: ["grid", "textScroll", 'btnLoading'],
      typeKeys: ['backgroundType', 'pressBackgroundType', 'isShowMenu'],
      colorSelectKeys: ["color", "backgroundColor", "pressBackgroundColor", 'circleColor', 'trackColor', 'activeColor', 'fontColor'],
      uploadImageKeys: [
        "backgroundImage",
        "pressBackgroundImage",
        "backPicSrc",
      ],
      checkboxKeys: ["kqds", "oneClick", "modalConfirm", "pressRing"],
      selectFormKeys: ["fontFamily", "textScrollDir", "textScrollSpeed", "backgroundSize", "direction", "imgStyle"],
      modalOperationKey: "",
    };
  },
  mounted() {
    this.initAttrNameData();
  },
  computed: {
    ...mapState([
      "canvasStyleData",
      "curComponent",
      "pageOptions",
      "componentData",
      "selectedCurrentPage",
      "curErrorMap"
    ]),
    isFormDisabled() {
      return this.curComponent.isLock;
    },
    isBackC() {
      return this?.curComponent?.component == "Back";
    },
    limitVal() {
      return (key, propKey) => {
        const val = ATTRS_VALIDATE_MAP[key] && ATTRS_VALIDATE_MAP[key][propKey];
        if (val !== null && val !== undefined && val !== '') {
          return val;
        }
        return undefined;
      };
    },
    errorMsg() {
      return (key) => {
        const id = this.curComponent.id;
        return this.curErrorMap[id] && this.curErrorMap[id][key];
      };
    },
    itemIsShow() {
      return (l) => {
        let isShow = true;
        if (l.key === 'btnLoadingTime') {
          isShow = !!this.curComponent.style.btnLoading;
        } else if (l.parentKey) {
          isShow = this.curComponent.style[l.parentKey] === l.typeValue;
        }
        return isShow;
      };
    }
  },
  watch: {
    curComponent: {
      handler(newV, oldValue) {
        if (
          (newV.style.pageName || oldValue.style.pageName) &&
          newV.component == "Back" //必须是背景组件才修改页面名称
        ) {
          //修改pageName时
          const d = this.pageOptions.map((p) => {
            if (p.value == this.selectedCurrentPage) {
              p.label = newV.style.pageName;
            }
            return p;
          });
          this.$store.commit("setPageOptions", d);
        }
        this.initAttrNameData(newV);
      },
      deep: true,
    },
  },
  methods: {
    imageModalConfirm(info) {
        this.curComponent.style[this.modalOperationKey] = info.file_down_path;
        this.curComponent.style[this.modalOperationKey + 'Info'] = info;
    },
    selectChange(l, val) {
      if (l.key === 'imgStyle') {
        let name = l.options.filter((item) => item.id === val)[0].text;
        this.curComponent.style.imgStyleName = name;
      }
    },
    inputValChange(key) {
      const validateFunc = ATTRS_VALIDATE_MAP[key] && ATTRS_VALIDATE_MAP[key].validate;
      if (validateFunc && typeof validateFunc === 'function') {
        eventBus.$emit("inputValidate", {
          key,
          val: this.curComponent.style[key],
          id: this.curComponent.id,
          otherVal: this.curComponent.style
        });
      }
    },
    addRtspAdressGroup() {
      this.$store.commit("changeRtspAdressGroups", {
        name: "",
        rtspAdress: "",
        id: new Date().valueOf(),
      });
    },
    deleteGroup(id) {
      this.$store.commit("deleteRtspAdressGroup", id);
    },
    addVideoNameGroup() {
      this.$store.commit("changeVideoNameGroups", {
        name: "",
        id: new Date().valueOf(),
      });
    },
    deleteVideoGroup(id) {
      this.$store.commit("deleteVideoGroup", id);
    },
    addDocumentNameGroup() {
      this.$store.commit("changeDocumentNameGroups", {
        name: "",
        id: new Date().valueOf(),
      });
    },
    deleteDocumentGroup(id) {
      this.$store.commit("deleteDocumentGroup", id);
    },
    showCarouselModal() {
      this.$refs.carouselSeletedPicModal.switchDialogVisible(true);
      const { carouselPics } = this.curComponent.style;
      // console.log(carouselPics);
      this.$refs.carouselSeletedPicModal.initCheckedPictures();
    },
    deleteCarouselPics() {
      this.$refs.carouselSeletedPicModal.confirmToChange(22, true);
    },
    setCarouselPics(v) {
      this.$refs.carouselSeletedPicModal.setCarouselPics(v);
    },
    showImagesResourceModal(key) {
      this.modalOperationKey = key;
      this.$refs.imagesResourceModal.switchDialogVisible(true);
    },
    resetImageDefaultSrc(key) {
      const all = this.componentData;
      // const index = this.componentData.findIndex(
      //   (i) => i.id == this.curComponent.id
      // );
      const ret = getCurComponentsInd(this.componentData, this.curComponent);
      let obj = {};
      if (key == "imageResource") {
        // 图片组件的处理
        obj = {
          ...this.curComponent,
          propValue: {
            pictureSrc: require("@/assets/title.jpg"),
            pictureName: "默认图片",
            pictureMd5: "default",
          },
        };
      } else {
        // 其他组件的图片属性处理
        obj = {
          ...this.curComponent,
          style: {
            ...this.curComponent.style,
            [key]: null,
            backgroundInfo: null,
            ...(key == "backgroundImage" ? { backgroundColor: "#409eff" } : {}),
          },
        };
      }
      // all.splice(index, 1, obj);
      if (ret.parentInd !== -1) {
        // 生态组件下的子组件
        all[ret.parentInd].propValue.splice(ret.index, 1, obj);
      } else {
        all.splice(ret.index, 1, obj);
      }
      this.$store.commit("setCurComponent", {
        component: obj,
      });
      this.$store.commit("setComponentData", all);
    },
    getPicSrc(s) {
      if (!s) return;
      return s.src.split("(")[1]?.split(")")[0];
    },
    initAttrNameData(newV) {
      const { component, style } = newV || this.curComponent;

      if (!style.textScroll && component == "v-text") {
        //处理 文字组件中的动态属性
        this.attrsCategories = {
          ...COMPONENT_ATTRS_CONFIG["v-text"],
          componentAttrs: COMPONENT_ATTRS_CONFIG[
            "v-text"
          ].componentAttrs.filter(
            (i) => i.key != "textScrollDir" && i.key != "textScrollSpeed"
          ),
        };
        return;
      }
      this.attrsCategories =
        COMPONENT_ATTRS_CONFIG[component] || NO_MATCH_COMPONENT_ATTRS;
    },
    pageNameChangeHandler(v) {
      this.$store.commit("setCurrPageName", v);
    },
    handleUploadChange(e, key) {
      const file = e.target.files[0];
      if (!file.type.includes("image")) {
        toast("只能插入图片");
        return;
      }

      const reader = new FileReader();
      reader.onload = (res) => {
        const img = new Image();
        const fileResult = res.target.result;
        img.onload = () => {
          if (!key) {
            this.$store.commit("setCanvasStyle", {
              ...this.canvasStyleData,
              backPicSrc: fileResult,
            });
          } else {
            this.$store.commit("setShapeSingleStyle", {
              key, //更新的style属性key
              value: `url(${fileResult})`,
            });
          }
        };
        img.src = fileResult;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="scss">
.attr-list {
  .el-input-number .el-input__inner {
    text-align: left;
  }
}
.resultRadioInfo {
  align-items: start !important;
}
.result-radio-attr {
  .el-radio-group {
    margin: 10px 0px;
  }
  .result-radio-attr-div {
    margin-top: 10px;
  }
}
</style>
<style lang="scss" scoped>
.attr-list {
  overflow: auto;
  padding: 0 20px 20px 0;
  height: 100%;
  .currPageBack {
    width: 200px;
    height: 200px;
  }
  .info {
    display: flex;
    align-items: center;
    margin: 16px 0;
    width: 100%;
    position: relative;
  }
  .twoLinesInfo {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .rtspAdressGroups {
    .addGroup {
      margin: 4px 0 10px;
    }
    .rtspAdressGroup {
      border: 1px solid #dadada;
      position: relative;
      padding: 6px;
      line-height: 40px;
      margin-bottom: 10px;
      margin-right: 20px;
      .deleteGroup {
        position: absolute;
        // top: 38px;
        right: -32px;
        font-size: 22px;
        cursor: pointer;
        color: #f56c6c;
        top: 50%;
        transform: translate(0px, -50%);
      }
    }
  }
  .label {
    font-size: 12px;
    color: #606266;
    margin: 10px 0;
    min-width: 56px;
    flex-wrap: nowrap;
    text-align: center;
    padding-right: 4px;
    flex-shrink: 0;
  }
  .el-checkbox__inner {
    width: 20px !important;
    height: 20px !important;
  }
  .locationInfo {
    border-bottom: 1px solid rgb(218, 218, 218);
    padding-bottom: 20px;
    .divider {
      font-size: 16px;
      line-height: 16px;
      color: rgb(34, 34, 34);
      height: 16px;
      text-align: left;
      border-left: 3px solid rgb(88, 159, 248);
      margin: 20px 0px;
      padding: 0px 10px;
    }
    .carouselRe {
      flex: 1;
      .el-button--small {
        width: 166px;
      }
      .resourceInfo {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        align-items: center;
        .icons {
          & i {
            margin-left: 6px;
            font-size: 20px;
            cursor: pointer;
          }
          & :nth-child(1) {
            color: #569ef8;
          }
          & :nth-child(2) {
            color: #f56c6c;
          }
        }
      }
    }
    .image {
      width: 68px;
      height: 68px;
    }
  }
}
</style>
