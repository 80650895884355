<template>
  <div>
    <div class="toolBarTitle">
      <div class="titleAndName">
        <el-button size="mini" style="margin: 0px 10px;" icon="el-icon-arrow-left" @click="dialogVisible = true">返回</el-button>
        <!-- <span class="icon-btn" @click="dialogVisible = true">
          <i class="el-icon-arrow-left"></i>
        </span> -->
        <!-- <span class="title">工程配置</span> -->
        <el-form :inline="true" size="mini" class="prop-config-form">
          <el-form-item>
            <el-input
              v-model="showProjectName"
              placeholder="请输入内容"
              @change="changeProjectName"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item v-for="p in pageSizeConfig" :key="p.key" :label="p.label">
            <el-input-number
              v-model.number="projectStyleData[p.key]"
              style="width:70px;"
              :min="0"
              :precision="0"
              :controls="false"
            ></el-input-number>
          </el-form-item>
        </el-form>

        <!-- <div class="searchInput">
          <div class="el-input-group__prepend">名称</div>
          <el-input
            placeholder="请输入内容"
            v-model="showProjectName"
            @change="changeProjectName"
            clearable
          >
          </el-input>
        </div>

        <div class="pageSizeConfig">
          <div class="config" v-for="p in pageSizeConfig" :key="p.key">
            <span class="label">{{ p.label }}</span>
            <el-input v-model="canvasStyleData[p.key]" />
          </div>
        </div> -->

        <div>
          <el-tooltip v-for="item in toolIconArr" :key="item.key" effect="dark" :content="item.tip" placement="bottom">
            <span :disabled="iconDisabled(item.key)" class="icon-btn" @click="toolIconClick(item.key)">
              <i :class="item.cls"></i>
            </span>
          </el-tooltip>
        </div>
      </div>
      <div>
        <el-tooltip class="item" effect="dark" placement="bottom-end">
          <div slot="content" class="jhb-tip-content">
            此功能拓展工程设备对外接收消息。<br />
            1.指令集设置：配置后可实现接收外部消息联动内部指令触发。<br />
            2.按钮状态配置：配置后可实现接收外部消息联动内部按钮状态变更。<br />
            <span style="color: red;margin-right:5px;">*</span>此功能需和工程一起保存！请配置后及时保存当前工程避免数据丢失！
          </div>
          <el-button icon="el-icon-warning" type="text" class="jhb-tip-btn"></el-button>
        </el-tooltip>
        <el-button
          v-for="item in rightIconArr"
          :key="item.key"
          size="mini"
          style="margin-left: 10px;"
          :icon="item.cls"
          @click="rightToolIconClick(item.key)"
        >{{item.tip}}</el-button>
        <!-- <el-tooltip v-for="item in rightIconArr" :key="item.key" effect="dark" :content="item.tip" placement="bottom">
          <span class="icon-btn" @click="rightToolIconClick(item.key)">
            <i :class="item.cls"></i>
          </span>
        </el-tooltip> -->
      </div>
    </div>
    <!-- 预览 -->
    <Preview v-model="isShowPreview" @change="handlePreviewChange" />
    <!-- 预览 -->
    <BatchSetBtns ref="batchSetBtnsDialog" @confirm="batchSetBtnsConfirm" />
    <!-- 交互包 -->
    <InteractionPackage ref="interPackRef"></InteractionPackage>
    <el-dialog
      :visible.sync="dialogVisible"
      width="340px"
      custom-class="closeDialog"
      :before-close="
        () => {
          this.dialogVisible = false;
        }
      "
    >
      <span>是否保存当前绘制效果并退出？ </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="() => backList(true)">
          保存并返回
        </el-button>
        <el-button size="small" type="primary" @click="() => backList(false)">
          不保存并返回
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import generateID from "@/utils/generateID";
import toast from "@/utils/toast";
import { mapState } from "vuex";
import Preview from "@/components/Editor/Preview";
import { commonStyle, commonAttr } from "@/custom-component/component-list";
import { BACK_COMPONENT, ATTRS_VALIDATE_MAP } from "@/utils/attrNameData";
import eventBus from "@/utils/eventBus";
import { deepCopy } from "@/utils/utils";
import { ECOLOGICAL_SIGN } from '@/constants/editor';
import BatchSetBtns from '@/components/BatchSetBtns';
import InteractionPackage from './InteractionPackage';

import _ from "lodash";

export default {
  components: { Preview, BatchSetBtns, InteractionPackage },
  data() {
    return {
      ECOLOGICAL_SIGN,
      isShowPreview: false,
      needToChange: [
        "top",
        "left",
        "width",
        "height",
        "fontSize",
        "borderWidth",
      ],
      scale: "100%",
      timer: null,
      dialogVisible: false,
      pageSizeConfig: [
        {
          label: "宽",
          key: "width",
        },
        {
          label: "高",
          key: "height",
        },
      ],
      project_name: "",
      isSaving: false,
      dataId: '',
      toolIconArr: [
        { cls: 'iconfont icon-revoke', tip: '撤销', key: 'revoke' },
        { cls: 'iconfont icon-empty', tip: '清空画布', key: 'empty' },
        { cls: 'el-icon-document-copy', tip: '复制页面', key: 'copy' },
        { cls: 'iconfont icon-save', tip: '保存页面', key: 'save' },
        { cls: 'iconfont icon-setup-batch', tip: '批量设置按钮', key: 'batchBtn' },
        { cls: 'iconfont icon-combination', tip: '组合', key: 'combination' },
        { cls: 'iconfont icon-split', tip: '拆分', key: 'split' },
        { cls: 'iconfont icon-lock', tip: '锁定', key: 'lock' },
        { cls: 'iconfont icon-unlock', tip: '解锁', key: 'unlock' },
        { cls: 'iconfont icon-vertical', tip: '垂直居中', key: 'vertical' },
        { cls: 'iconfont icon-left-alignment', tip: '左对齐', key: 'toLeft' },
        { cls: 'iconfont icon-right-alignment', tip: '右对齐', key: 'toRight' },
        { cls: 'iconfont icon-top-alignment', tip: '上对齐', key: 'toTop' },
        { cls: 'iconfont icon-bottom-alignment', tip: '下对齐', key: 'toBottom' },
        { cls: 'iconfont icon-horizontal-alignment', tip: '水平等间距', key: 'toHEqual' },
        { cls: 'iconfont icon-vertical-alignment', tip: '垂直等间距', key: 'toVEqual' }
      ],
      rightIconArr: [
        { cls: 'el-icon-folder-opened', tip: '交互包', key: 'interactionPackage' },
        { cls: 'el-icon-view', tip: '预览', key: 'preview' },
        { cls: 'el-icon-folder-checked', tip: '保存工程', key: 'save' }
      ]
    };
  },
  props: {
    projectName: String,
    changeProjectName: Function,
  },
  watch: {
    projectStyleData: {
      handler: function(val, oldVal) {
        // 宽高改变时需要改变本地存储
        let canvasStyle = JSON.parse(sessionStorage.getItem("canvasStyle"));
        canvasStyle[this.selectedCurrentPage].width = val.width;
        canvasStyle[this.selectedCurrentPage].height = val.height;
        this.$store.commit(
          "setCanvasStyle",
          canvasStyle[this.selectedCurrentPage]
        );
      },
    deep: true
    }
  },
  computed: {
    ...mapState([
      "componentData",
      "canvasStyleData",
      "projectStyleData",
      "areaData",
      "curComponent",
      "selectedCurrentPage",
      "pageOptions",
    ]),
    showProjectName: {
      get() {
        return this.projectName;
      },
      set(v) {
        this.changeProjectName(v);
      },
    },
    iconDisabled() {
      return (key) => {
        switch(key) {
          // 组合
          case "combination":
            return !this.areaData.components.length;
            break;
          // 拆分
          case "split":
            return !this.curComponent ||
                this.curComponent.isLock ||
                this.curComponent.compType === ECOLOGICAL_SIGN ||
                this.curComponent.component != 'Group';
            break;
          // 锁定
          case "lock":
            return !this.curComponent || this.curComponent.isLock;
            break;
          // 解锁
          case "unlock":
            return !this.curComponent || !this.curComponent.isLock;
            break;
          // 垂直居中
          case 'vertical':
            return !this.curComponent || this.curComponent.id === 'Back';
            break;
          // 左对齐
          case 'toLeft':
          case 'toRight':
          case 'toTop':
          case 'toBottom':
          case 'toHEqual':
          case 'toVEqual':
            return !this.areaData.components.length;
            break;
        }
        return false;
      }
    }
  },
  created() {
    eventBus.$on("preview", this.preview);
    eventBus.$on("save", this.save);
    eventBus.$on("clearCanvas", this.clearCanvas);
    this.scale = this.canvasStyleData.scale;
    this.initAutoSave();
  },
  destroyed() {
    this.clearLocalstorage();
    clearInterval(this.autoSaveCycle);
  },
  methods: {
    async backList(isSave) {
      if (isSave) {
        let ret = await this.saveProject();
        if (ret) {
          // 保存成功就离开当前页面
          this.$router.push("/projects");
        }
      } else {
        this.$router.push("/projects");
      }
    },
    rightToolIconClick(key) {
      switch (key) {
        case 'interactionPackage':
          this.$refs.interPackRef.show();
          break;
        case 'preview':
          this.preview();
          break;
        case 'save': 
          this.saveProject();
          break;
        default:
          break;
      }
    },
    toolIconClick(key) {
      if (this.iconDisabled(key)) {
        return;
      }
      switch (key) {
        // 撤销
        case "revoke":
          this.undo();
          break;
        // 清空画布
        case "empty":
          this.clearCanvas();
          break;
        case 'batchBtn':
          this.batchSetBtn();
          break;
        case 'copy': 
          this.copyPage();
          break;
        case "save":
          this.save(false);
          break;
        // 组合
        case "combination":
          this.compose();
          break;
        // 拆分
        case "split":
          this.decompose();
          break;
        // 锁定
        case "lock":
          this.lock();
          break;
        // 解锁
        case "unlock":
          this.unlock();
          break;
        // 垂直居中
        case 'vertical':
          this.handleVertical();
          break;
        // 左对齐
        case 'toLeft':
          this.handleLeft();
          break;
        // 右对齐
        case 'toRight':
          this.handleRight();
          break;
        // 上对齐
        case 'toTop':
          this.handleTop();
          break;
        // 下对齐
        case 'toBottom':
          this.handleBootom();
          break;
        // 水平等间距
        case 'toHEqual':
          this.handleHEqual();
          break;
        // 垂直等间距
        case 'toVEqual':
          this.handleVEqual();
          break;
        default:
          break;
      }
    },
    batchSetBtnsConfirm(datas) {
      let map = {};
      datas.map((item) => {
        map[item.id] = item;
      });
      this.$store.commit("batchSetComponentAttr", map);
    },
    pageOperClick(key) {
      switch (key) {
        case 'batchBtn':
          this.batchSetBtn();
          break;
        case 'copy': 
          this.copyPage();
          break;
        case "preview":
          this.preview();
          break;
        case "save":
          this.save(false);
          break;
      }
    },
    batchSetBtn() {
      this.$refs.batchSetBtnsDialog.show();
    },
    copyPage() {
      const { pages } =
        (atob(this.$route.query.limits) &&
          JSON.parse(atob(this.$route.query.limits))) ||
        {};
      //判断是否超套餐个数
      if (this.pageOptions.length >= pages * 1) {
        this.$message.error("不得超过页面最高限制个数");
        return;
      }
      // 复制页面
      this.$emit('copyPage');
    },
    canvasOperClick(key) {
      switch (key) {
        // 撤销
        case "revoke":
          this.undo();
          break;
        // 清空画布
        case "empty":
          this.clearCanvas();
          break;
        // 组合
        case "combination":
          this.compose();
          break;
        // 拆分
        case "split":
          this.decompose();
          break;
        // 锁定
        case "lock":
          this.lock();
          break;
        // 解锁
        case "unlock":
          this.unlock();
          break;
        // 垂直居中
        case 'vertical':
          this.handleVertical();
          break;
        // 左对齐
        case 'toLeft':
          this.handleLeft();
          break;
        // 右对齐
        case 'toRight':
          this.handleRight();
          break;
        // 上对齐
        case 'toTop':
          this.handleTop();
          break;
        // 下对齐
        case 'toBottom':
          this.handleBootom();
          break;
        // 水平等间距
        case 'toHEqual':
          this.handleHEqual();
          break;
        // 垂直等间距
        case 'toVEqual':
          this.handleVEqual();
          break;
      }
    },
    handleLeft() {
      this.$store.commit("componentsAlign", 'left');
      this.$store.commit("recordSnapshot");
    },
    handleRight() {
      this.$store.commit("componentsAlign", 'right');
      this.$store.commit("recordSnapshot");
    },
    handleTop() {
      this.$store.commit("componentsAlign", 'top');
      this.$store.commit("recordSnapshot");
    },
    handleBootom() {
      this.$store.commit("componentsAlign", 'bootom');
      this.$store.commit("recordSnapshot");
    },
    handleHEqual() {
      this.$store.commit("horizontalEqual");
      this.$store.commit("recordSnapshot");
    },
    handleVEqual() {
      this.$store.commit("verticalEqual");
      this.$store.commit("recordSnapshot");
    },
    handleVertical() {
      let finalTop = this.canvasStyleData.height / 2 - this.curComponent.style.height / 2;
      this.$store.commit('setShapeSingleStyle', { key: 'top', value: parseInt(finalTop) });
    },
    initAutoSave() {
      this.autoSaveCycle = setInterval(() => {
        this.save(true);
      }, 1000);
    },
    getAllPictures(data) {
      return _.flattenDeep(Object.values(JSON.parse(data))).reduce((res, c) => {
        if (
          c.component == "Back" ||
          c.component == "v-button" ||
          c.component == "MatrixIn" ||
          c.component == "MatrixOut" ||
          c.component == "MatrixExecute" ||
          c.component == "Carousel"
        ) {
          c.style.backgroundInfo &&
            c.style.backgroundInfo.id &&
            res.push(c.style.backgroundInfo);

          c.style.pressBackgroundImageInfo &&
            c.style.pressBackgroundImageInfo.id &&
            res.push(c.style.pressBackgroundImageInfo);
          if (c.style.carouselPics) {
            //轮播图组件
            res = res.concat(c.style.carouselPics);
          }
        } else if (c.component == "Picture") {
          if (c.propValue?.id != "default") {
            res.push(c.propValue);
          }
        } else if (c.component === 'StatusLight') {
          // 状态灯
          if (c.style.lightBackground === 'icon' && c.style.lightBackgroundImage) {
            res.push(c.style.lightBackgroundImageInfo);
          }
          if (c.activeDetectionConfig.validateCorrect === 'icon' && c.activeDetectionConfig.validateCorrectIconInfo) {
            res.push(c.activeDetectionConfig.validateCorrectIconInfo);
          }
          if (c.activeDetectionConfig.validateFail === 'icon' && c.activeDetectionConfig.validateFailIconInfo) {
            res.push(c.activeDetectionConfig.validateFailIconInfo);
          }
          if (c.activeDetectionConfig.pingStatus === '1') {
            if (c.activeDetectionConfig.pingCorrect === 'icon' && c.activeDetectionConfig.pingCorrectIconInfo) {
              res.push(c.activeDetectionConfig.pingCorrectIconInfo);
            }
            if (c.activeDetectionConfig.pingFail === 'icon' && c.activeDetectionConfig.pingFailIconInfo) {
              res.push(c.activeDetectionConfig.pingFailIconInfo);
            }
          }
          let devices = c.passiveMonitorConfig.devices;
          if (devices && devices.length !== 0) {
            devices.forEach((item) => {
              let cells = item.deviceCalls;
              cells.forEach((cellItem) => {
                if (cellItem.callbackStatus === 'icon' && cellItem.callbackStatusIconInfo) {
                  res.push(cellItem.callbackStatusIconInfo);
                }
              });
            });
          }
        }
        return res;
      }, []);
    },
    validateProject() {
      // 每个页面的每个组件都需要挨个再次校验一遍
      let pageDatas = JSON.parse(window.sessionStorage.getItem("canvasData"));
      for (let pageKey in pageDatas) {
        let currentPage = pageDatas[pageKey];
        currentPage.map((comp) => {
          // if (comp.id !== 'Back') {
            this.validateCompKeys(comp, 'style', pageKey);
            this.validateCompKeys(comp, 'networkConfig', pageKey);
          // }
        });
      }
    },
    validateCompKeys(comp, key, pageKey) {
      if (comp[key]) {
        for (let props in comp[key]) {
          this.validateProps(comp, key, props, pageKey);
        }
      }
    },
    validateProps(comp, configKey, key, pageKey) {
      const validateFunc = ATTRS_VALIDATE_MAP[key] && ATTRS_VALIDATE_MAP[key].validate;
      if (validateFunc && typeof validateFunc === 'function') {
        eventBus.$emit("inputValidate", {
          key,
          val: comp[configKey][key],
          id: comp.id,
          otherVal: comp[configKey],
          pageKey
        });
      }
    },
    async saveProject(callback) {
      if (!this.projectName || this.projectName.trim() === '') {
        this.$message.error('工程名称不能为空');
        return false;
      }
      // 验证有验证项的所有数据，避免新增时候就有不符合要求的数据
      this.validateProject();

      // 检查有没有不符合要求的数据
      const remainErrorData =
          (sessionStorage.getItem("canvasErrorMap") &&
            JSON.parse(sessionStorage.getItem("canvasErrorMap"))) ||
          {};
      let isError = false;
      for (let pageId in remainErrorData) {
        if (isError) {
          break;
        }
        let pageInfo = remainErrorData[pageId];
        for (let componentId in pageInfo) {
          if (isError) {
            break;
          }
          let attrInfo = pageInfo[componentId];
          for (let attrId in attrInfo) {
            if (typeof(attrInfo[attrId]) === 'string' && attrInfo[attrId]) {
              isError = true;
              break;
            } else if (typeof(attrInfo[attrId]) === 'object') {
              // 状态灯的-被动监听，多个设备时，需要再次嵌套
              for (let deviceAttrId in attrInfo[attrId]) {
                if (attrInfo[attrId][deviceAttrId]) {
                  isError = true;
                  break;
                }
              }
            }
          }
        }
      }
      if (isError) {
        this.$message.error('有不正确的数据，已标红，请检查并修改后再保存');
        return false;
      }
      const { id } = this.$route.query;
      const { project_code } =
        (atob(this.$route.query.limits) &&
          JSON.parse(atob(this.$route.query.limits))) ||
        {};
      const { width, height } = this.projectStyleData;
      const data = sessionStorage.getItem("canvasData");
      const allPictures = _.uniqBy(this.getAllPictures(data), "id");
      const interactionPackageData = sessionStorage.getItem("interactionPackageData");
      this.isSaving = true;
      this.$changeLoadingState(true);
      let isSuccess = false;
      try {
        let res = await this.$request.appOrUpdateProject({
          id: id || this.dataId,
          project_name: this.projectName,
          project_width: width + "",
          project_height: height + "",
          file_resouces: allPictures,
          page_sort: this.pageOptions.map((item) => item.value),
          panel_data: JSON.stringify({
            ...JSON.parse(data),
            [this.selectedCurrentPage]: this.componentData,
          }),
          interaction_package_data: interactionPackageData,
          type: project_code,
        });
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        } else {
          this.$message.success('保存成功');
          this.dataId = res.data && res.data.id;
        }
        isSuccess = true;
      } catch (error) {
        // 
        this.$message.error('保存失败');
      }
      this.isSaving = false;
      this.$changeLoadingState(false);
      return isSuccess;
      // this.$request
      //   .appOrUpdateProject({
      //     id: id || this.dataId,
      //     project_name: this.projectName,
      //     project_width: width + "",
      //     project_height: height + "",
      //     file_resouces: allPictures,
      //     page_sort: this.pageOptions.map((item) => item.value),
      //     panel_data: JSON.stringify({
      //       ...JSON.parse(data),
      //       [this.selectedCurrentPage]: this.componentData,
      //     }),
      //     type: project_code,
      //   })
      //   .then((res) => {
      //     if (res.status != "success") {
      //       this.$message.error(res.msg);
      //       return;
      //     } else {
      //       this.$message.success('保存成功');
      //       this.dataId = res.data && res.data.id;
      //     }
      //     // this.$router.push("/projects");
      //   })
      //   .finally(() => {
      //     this.isSaving = false;
      // this.$changeLoadingState(false);
      //   });
    },
    clearLocalstorage() {
      this.clearCanvas();
      this.$store.commit("setPageOptions", [
        {
          label: "新页面1",
          value: 1,
        },
      ]);
      this.$store.commit("setCanvasStyle", {
        width: 1200,
        height: 740,
        scale: 100,
      });
      this.$store.commit("setProjectStyle", {
        width: 1200,
        height: 740,
        scale: 100,
      });
      sessionStorage.removeItem("canvasData");
      sessionStorage.removeItem("canvasErrorMap");
      sessionStorage.removeItem("canvasStyle");
      sessionStorage.removeItem("interactionPackageData");
    },
    closeEditProject() {},
    format(value) {
      const scale = this.scale;
      return (value * parseInt(scale)) / 100;
    },

    getOriginStyle(value) {
      const scale = this.canvasStyleData.scale;
      const result = value / (parseInt(scale) / 100);
      return result;
    },

    handleScaleChange() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        // 画布比例设一个最小值，不能为 0
        // eslint-disable-next-line no-bitwise
        this.scale = ~~this.scale || 1;
        const componentData = deepCopy(this.componentData);
        componentData.forEach((component) => {
          Object.keys(component.style).forEach((key) => {
            if (this.needToChange.includes(key)) {
              // 根据原来的比例获取样式原来的尺寸
              // 再用原来的尺寸 * 现在的比例得出新的尺寸
              // 不能用 Math.round 防止 1 px 的边框变 0
              component.style[key] = Math.ceil(
                this.format(this.getOriginStyle(component.style[key]))
              );
            }
          });
        });

        this.$store.commit("setComponentData", componentData);
        this.$store.commit("setCanvasStyle", {
          ...this.canvasStyleData,
          scale: this.scale,
        });
        this.$store.commit("setProjectStyle", {
          ...this.projectStyleData,
          scale: this.scale,
        });
      }, 1000);
    },

    lock() {
      if (this.curComponent.id === 'Back') {
        this.$message.error('请先点击选择要锁定组件');
        return;
      }
      this.$store.commit("lock");
      if (this.curComponent.compType === ECOLOGICAL_SIGN) {
        // 生态组件要将子组件下的一并处理了
        this.curComponent.propValue.map((item, index) => {
          this.$store.commit("setEcologicalChildData", {
            index: index,
            key: 'isLock',
            value: true
          });
        });
      }
    },
    toside(key) {
      this.$store.commit("componentsToSide", key);
    },

    unlock() {
      if (this.curComponent.id === 'Back') {
        this.$message.error('请先点击选择要锁定组件');
        return;
      }
      this.$store.commit("unlock");
      if (this.curComponent.compType === ECOLOGICAL_SIGN) {
        // 生态组件要将子组件下的一并处理了
        this.curComponent.propValue.map((item, index) => {
          this.$store.commit("setEcologicalChildData", {
            index: index,
            key: 'isLock',
            value: false
          });
        });
      }
    },

    compose() {
      this.$store.commit("compose");
      this.$store.commit("recordSnapshot");
    },

    decompose() {
      this.$store.commit("decompose");
      this.$store.commit("recordSnapshot");
    },

    undo() {
      this.$store.commit("undo");
    },

    redo() {
      this.$store.commit("redo");
    },

    handleFileChange(e) {
      const file = e.target.files[0];
      if (!file.type.includes("image")) {
        toast("只能插入图片");
        return;
      }

      const reader = new FileReader();
      reader.onload = (res) => {
        const fileResult = res.target.result;
        const img = new Image();
        img.onload = () => {
          this.$store.commit("addComponent", {
            component: {
              ...commonAttr,
              id: generateID(),
              component: "Picture",
              label: "图片",
              icon: "",
              propValue: fileResult,
              style: {
                ...commonStyle,
                top: 0,
                left: 0,
                width: img.width,
                height: img.height,
              },
            },
          });

          this.$store.commit("recordSnapshot");

          // 修复重复上传同一文件，@change 不触发的问题
          document.querySelector("#input").setAttribute("type", "text");
          document.querySelector("#input").setAttribute("type", "file");
        };

        img.src = fileResult;
      };

      reader.readAsDataURL(file);
    },

    preview() {
      this.isShowPreview = true;
      this.$store.commit("setEditMode", "preview");
    },

    save(hideTip) {
      const remainComponentData =
        (sessionStorage.getItem("canvasData") &&
          JSON.parse(sessionStorage.getItem("canvasData"))) ||
        {};
      const remainCanvasStyle =
        (sessionStorage.getItem("canvasStyle") &&
          JSON.parse(sessionStorage.getItem("canvasStyle"))) ||
        {};
      const remainErrorData =
        (sessionStorage.getItem("canvasErrorMap") &&
          JSON.parse(sessionStorage.getItem("canvasErrorMap"))) ||
        {};
      if (!remainErrorData[this.selectedCurrentPage]) {
        // 新加了页面，需要设置当前页面的key
        sessionStorage.setItem(
          "canvasErrorMap",
          JSON.stringify({
            ...remainErrorData,
            [this.selectedCurrentPage]: {}
          })
        );
        this.$store.commit("setCurErrorMap");
      }
      let datas = JSON.stringify({
        ...remainComponentData,
        [this.selectedCurrentPage]: this.componentData,
      });
      // console.log(datas);
      sessionStorage.setItem(
        "canvasData",
        datas
      );
      
      sessionStorage.setItem(
        "canvasStyle",
        JSON.stringify({
          ...remainCanvasStyle,
          [this.selectedCurrentPage]: this.canvasStyleData[this.selectedCurrentPage] || this.canvasStyleData,
        })
      );
      if (hideTip) {
        console.log("自动保存画布");
      } else {
        this.$message.success({
          message: "保存成功",
          offset: 66,
          customClass: "saveMessage",
        });
      }
    },

    clearCanvas() {
      this.$store.commit("setCurComponent", {
        component: BACK_COMPONENT(),
      });
      this.$store.commit("setComponentData", [BACK_COMPONENT()]);
      this.$store.commit("recordSnapshot");
      sessionStorage.removeItem("canvasErrorMap");
    },

    handlePreviewChange() {
      this.$store.commit("setEditMode", "edit");
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-btn {
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0px 4px;
  &:hover {
    background: #ebebeb;
  }
  &[disabled] {
    color: #ccc;
  }
}
.oper-tool-dropdown {
  margin-right: 10px;
}
</style>
<style lang="scss">
.jhb-tip-content {
  line-height: 24px;
}
.jhb-tip-btn, .jhb-tip-btn:hover {
  font-size: 16px;
  color: red;
}
.toolbar {
  padding: 15px 10px;
  white-space: nowrap;
  overflow-x: auto;
  background: #fff;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .canvas-config {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    color: #606266;

    input {
      width: 50px;
      margin-left: 10px;
      outline: none;
      padding: 0 5px;
      border: 1px solid #ddd;
      color: #606266;
    }

    span {
      margin-left: 10px;
    }
  }

  .insert {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: 0.1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-left: 10px;

    &:active {
      color: #3a8ee6;
      border-color: #3a8ee6;
      outline: 0;
    }

    &:hover {
      background-color: #ecf5ff;
      color: #3a8ee6;
    }
  }
}
.toolBarTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 4px;
  padding-left: 0px;
  .el-button--mini {
    padding: 7px 8px;
  }
  .el-input-number.is-without-controls .el-input__inner {
    padding: 0px 8px;
  }
  .titleAndName {
    display: flex;
    align-items: center;
    .title {
      font-size: 22px;
      font-weight: 700;
      line-height: 40px;
      display: inline-block;
      -webkit-box-flex: 0;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 20px;
    }
    .prop-config-form {
      .el-form-item--mini.el-form-item {
        margin-bottom: 0px;
      }
      .el-input-number .el-input__inner {
        text-align: left;
      }
    }
    & span {
      flex-shrink: 0;
    }
    .searchInput {
      font-size: 18px;
      background-color: rgb(238, 238, 238);
      border-radius: 40px;
      overflow: hidden;
      display: flex;
      height: 42px;
      .el-input-group__prepend {
        color: #222;
        width: 70px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .el-input {
        text-align: center;
        color: rgb(34, 34, 34);
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
      }
      .el-input__inner {
        border-radius: 0 24px 24px 0;
        height: 42px !important;
      }
    }
  }
  .pageSizeConfig {
    display: flex;
    .config {
      margin-left: 12px;
      display: flex;
      align-items: center;
      .label {
        font-size: 16px;
        color: #222;
        margin-right: 6px;
      }
      .el-input {
        width: 150px;
      }
    }
  }
  // .saveProject {
  //   height: 42px;
  //   width: 104px;
  //   font-size: 16px;
  //   margin-left: 12px;
  // }
}
.closeDialog {
  .el-dialog__body {
    text-align: center;
    font-size: 16px;
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
.saveMessage {
  top: 66px;
  z-index: 2000;
  left: 70px;
  min-width: 100px !important;
}
</style>
