<template>
    <div>
        <div class="item" :class="{ 'deviceItem': config.key === 'devices'}" v-for="config in PASSIVE_MONITOR_CONFIG" :key="config.key">
            <div class="label">
                {{ config.label }}
                <el-button v-if="config.key === 'devices'" type="text" class="add-device-btn" @click="addDeviceBtn">添加设备</el-button>
            </div>
            <div class="comp">
                <el-radio-group v-if="config.type === 'radio'" v-model="curComponent.passiveMonitorConfig[config.key]">
                    <el-radio v-for="item in config.options" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
                </el-radio-group>
                <template v-else-if="config.key === 'devices'">
                    <el-collapse v-model="deviceOpenKeys" @change="handleOpenChange">
                        <el-collapse-item v-for="(device, deviceInd) in curComponent.passiveMonitorConfig.devices" :key="device.id" :name="device.id">
                            <template slot="title">
                                设备{{ deviceInd + 1 }}<i v-if="curComponent.passiveMonitorConfig.devices && curComponent.passiveMonitorConfig.devices.length > 1" class="el-icon-delete delete-device-icon" @click.stop="deleteDevice(device.id)"></i>
                            </template>
                            <div class="device-item" v-for="n in PASSIVE_MONITOR_DEVICE_CONFIG" :key="n.key" :class="{'device-calls-item': n.type === 'deviceCalls'}">
                                <div class="label">{{ n.label }}</div>
                                <div class="comp">
                                    <span v-if="!!n.prefix" class="prefixLabel">{{ n.prefix }}</span>
                                    <template v-if="n.type === 'input'">
                                        <el-input-number
                                            v-if="n.inputType === 'number'"
                                            v-model="device[n.key]"
                                            :placeholder="`请输入${n.label}`"
                                            @keydown.native.stop
                                            :controls="false"
                                            :min="limitVal(n.key, 'min')"
                                            :precision="limitVal(n.key, 'precision')"
                                            @change="inputValChange(n.key, device[n.key], device.id)"
                                        />
                                        <el-input
                                            v-else
                                            v-model="device[n.key]"
                                            :placeholder="`请输入${n.label}`"
                                            @keydown.native.stop
                                            @change="inputValChange(n.key, device[n.key], device.id)"
                                        />
                                    </template>
                                    <template v-else-if="n.type === 'deviceCalls'">
                                        <template v-if="device[n.key] && device[n.key].length !== 0">
                                            <el-card v-for="(callInfo, callInd) in device[n.key]" :key="callInfo.id" shadow="never">
                                                <div slot="header">
                                                    <span>回码信息{{ callInd + 1 }}</span>
                                                    <div class="call-right-btn">
                                                        <el-button size="small" type="text" @click="addDeviceCall(device.id)">添加</el-button>
                                                        <el-button v-if="device[n.key].length > 1" size="small" type="text" @click="deleteDeviceCall(device.id, callInfo.id)">删除</el-button>
                                                    </div>
                                                </div>
                                                <template v-for="info in DEVICE_CALLBACK_CONFIG">
                                                    <div class="call-item" v-if="info.key === 'enterSpaceName' ? callInfo.callbackUnicode === '1' : true" :key="info.key">
                                                        <div class="label">{{ info.label }}</div>
                                                        <div class="comp">
                                                            <template v-if="info.type === 'input'">
                                                                <el-input
                                                                    v-model="callInfo[info.key]"
                                                                    :placeholder="`请输入${info.label}`"
                                                                    @keydown.native.stop
                                                                />
                                                            </template>
                                                            <el-radio-group v-else-if="info.type === 'radio'" v-model="callInfo[info.key]">
                                                                <el-radio v-for="item in info.options" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
                                                            </el-radio-group>
                                                            <template v-else-if="info.type === 'resultRadio'">
                                                                <el-radio-group v-model="callInfo[info.key]">
                                                                    <el-radio label="color">颜色</el-radio>
                                                                    <el-radio label="icon">图标</el-radio>
                                                                </el-radio-group>
                                                                <div>
                                                                    <el-color-picker
                                                                        v-if="callInfo[info.key] === 'color'"
                                                                        v-model="callInfo[info.colorKey]"
                                                                    />
                                                                    <UploadArea
                                                                        v-if="callInfo[info.key] === 'icon'"
                                                                        :handler="
                                                                        () => {
                                                                            showImagesResourceModal(info.iconKey, device.id, callInfo.id);
                                                                        }
                                                                        "
                                                                        :deleteHandler="
                                                                        () => {
                                                                            resetImageDefaultSrc(info.iconKey, device.id, callInfo.id);
                                                                        }
                                                                        "
                                                                        :src="`url(${callInfo[info.iconKey]})`"
                                                                    />
                                                                </div>
                                                            </template>
                                                            <el-select
                                                                v-else-if="info.type === 'select'"
                                                                v-model="callInfo[info.key]"
                                                                placeholder="请选择"
                                                                :style="info.width ? {width: info.width} : {}"
                                                            >
                                                                <el-option
                                                                    v-for="o in info.options"
                                                                    :key="o.value"
                                                                    :label="o.label"
                                                                    :value="o.value"
                                                                >
                                                                </el-option>
                                                            </el-select>
                                                        </div>
                                                    </div>
                                                </template>
                                                <!-- <div class="call-item" v-for="info in DEVICE_CALLBACK_CONFIG" :key="info.key">
                                                    <div class="label">{{ info.label }}</div>
                                                    <div class="comp">
                                                        <template v-if="info.type === 'input'">
                                                            <el-input
                                                                v-model="callInfo[info.key]"
                                                                :placeholder="`请输入${info.label}`"
                                                                @keydown.native.stop
                                                            />
                                                        </template>
                                                        <el-radio-group v-else-if="info.type === 'radio'" v-model="callInfo[info.key]">
                                                            <el-radio v-for="item in info.options" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
                                                        </el-radio-group>
                                                        <template v-else-if="info.type === 'resultRadio'">
                                                            <el-radio-group v-model="callInfo[info.key]">
                                                                <el-radio label="color">颜色</el-radio>
                                                                <el-radio label="icon">图标</el-radio>
                                                            </el-radio-group>
                                                            <div>
                                                                <el-color-picker
                                                                    v-if="callInfo[info.key] === 'color'"
                                                                    v-model="callInfo[info.colorKey]"
                                                                />
                                                                <UploadArea
                                                                    v-if="callInfo[info.key] === 'icon'"
                                                                    :handler="
                                                                    () => {
                                                                        showImagesResourceModal(info.iconKey, device.id, callInfo.id);
                                                                    }
                                                                    "
                                                                    :deleteHandler="
                                                                    () => {
                                                                        resetImageDefaultSrc(info.iconKey, device.id, callInfo.id);
                                                                    }
                                                                    "
                                                                    :src="`url(${callInfo[info.iconKey]})`"
                                                                />
                                                            </div>
                                                        </template>
                                                        <el-select
                                                            v-else-if="info.type === 'select'"
                                                            v-model="callInfo[info.key]"
                                                            placeholder="请选择"
                                                            :style="info.width ? {width: info.width} : {}"
                                                        >
                                                            <el-option
                                                                v-for="o in info.options"
                                                                :key="o.value"
                                                                :label="o.label"
                                                                :value="o.value"
                                                            >
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                </div> -->
                                            </el-card>
                                        </template>
                                    </template>
                                    <el-select
                                        v-else-if="n.type === 'select'"
                                        v-model="device[n.key]"
                                        placeholder="请选择"
                                        :style="n.width ? {width: n.width} : {}"
                                    >
                                        <el-option
                                            v-for="o in n.options"
                                            :key="o.value"
                                            :label="o.label"
                                            :value="o.value"
                                        >
                                        </el-option>
                                    </el-select>
                                    <template v-else-if="n.key === 'bindDirectives'">
                                        <el-button type="primary" size="small" @click="addDirectionClick(device.id)">添加指令</el-button>
                                        <div class="directives" v-if="device[n.key] && device[n.key].length !== 0">
                                            <el-tag
                                                type="info"
                                                v-for="d in device[n.key]"
                                                :key="d.id"
                                                closable
                                                @close="deleteDirective(d, deviceInd)"
                                            >{{ d.instruction_name }}</el-tag
                                            >
                                        </div>
                                    </template>
                                    <span v-if="!!n.suffix" class="suffixLabel">{{ n.suffix }}</span>
                                    <div v-if="!!errorMsg(n.key, device.id)" class="attr-error-tip">{{errorMsg(n.key, device.id) || ''}}</div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </template>
            </div>
        </div>
        <!-- 图片资源的modal -->
        <ImagesResourceModal
            ref="imagesResourceModal"
            :modalOperationKey="modalOperationKey"
            @confirm="imageModalConfirm"
        />
        <!-- 选择指令 -->
        <DirectivesModalForLight ref="directivesModal" @confirm="directivesModalConfirm" />
    </div>
</template>
<script>
import { PASSIVE_MONITOR_CONFIG, PASSIVE_MONITOR_DEVICE_CONFIG, ATTRS_VALIDATE_MAP, INIT_PASSIVE_DEVICE_DATA, DEVICE_CALLBACK_CONFIG } from '../utils/attrNameData';
import { mapState } from 'vuex';
import eventBus from '@/utils/eventBus';
import UploadArea from "@/components/UploadArea";
import ImagesResourceModal from "@/components/ImagesResourceModal";
import DirectivesModalForLight from './DirectivesModalForLight';
import { uuid } from '../utils/utils';

export default {
    data() {
        return {
            PASSIVE_MONITOR_CONFIG,
            PASSIVE_MONITOR_DEVICE_CONFIG,
            DEVICE_CALLBACK_CONFIG,
            modalOperationKey: '',
            deviceOpenKeys: [1],
            addDeviceId: '', // 添加指令弹窗打开时的设备id
            imageSelectDeviceId: '',
            imageSelectCallId: ''
        };
    },
    components: {
        UploadArea,
        ImagesResourceModal,
        DirectivesModalForLight
    },
    computed: {
        ...mapState(["curComponent", "curErrorMap", "selectedCurrentPage"]),
        limitVal() {
            return (key, propKey) => {
                const val = ATTRS_VALIDATE_MAP[key] && ATTRS_VALIDATE_MAP[key][propKey];
                if (val !== null && val !== undefined && val !== '') {
                    return val;
                }
                return undefined;
            };
        },
        errorMsg() {
            return (key, deviceId) => {
                const id = this.curComponent.id;
                return this.curErrorMap[id] && this.curErrorMap[id]['device_' + deviceId] && this.curErrorMap[id]['device_' + deviceId][key];
            };
        }
    },
    methods: {
        addDeviceCall(deviceId) {
            let ind = this.curComponent.passiveMonitorConfig.devices.findIndex(item => item.id === deviceId);
            this.curComponent.passiveMonitorConfig.devices[ind].deviceCalls.push({
                id: uuid(), callbackInfo: '', callbackUnicode: '1', callbackStatus: 'color', callbackStatusColor: '#67c23a', callbackStatusIcon: '', enterSpaceName: '0'
            });
        },
        deleteDeviceCall(deviceId, callId) {
            let ind = this.curComponent.passiveMonitorConfig.devices.findIndex(item => item.id === deviceId);
            let callDatas = this.curComponent.passiveMonitorConfig.devices[ind].deviceCalls;
            let callInd = callDatas.findIndex(item => item.id === callId);
            this.curComponent.passiveMonitorConfig.devices[ind].deviceCalls.splice(callInd, 1);
        },
        imageModalConfirm(info) {
            debugger;
            let ind = this.curComponent.passiveMonitorConfig.devices.findIndex(item => item.id === this.imageSelectDeviceId);
            let callDatas = this.curComponent.passiveMonitorConfig.devices[ind].deviceCalls;
            let callInd = callDatas.findIndex(item => item.id === this.imageSelectCallId);
            this.curComponent.passiveMonitorConfig.devices[ind].deviceCalls[callInd][this.modalOperationKey] = info.file_down_path;
            this.curComponent.passiveMonitorConfig.devices[ind].deviceCalls[callInd][this.modalOperationKey + 'Info'] = info;
        },
        addDeviceBtn() {
            let obj = Object.assign({}, INIT_PASSIVE_DEVICE_DATA);
            obj.id = uuid();
            this.curComponent.passiveMonitorConfig.devices.push(obj);
        },
        deleteDevice(id) {
            let deviceInd = this.curComponent.passiveMonitorConfig.devices.findIndex(item => item.id === id);
            console.log(deviceInd);
            this.curComponent.passiveMonitorConfig.devices.splice(deviceInd, 1);
            // 删除打开的设备id
            let openInd = this.deviceOpenKeys.indexOf(id);
            this.deviceOpenKeys.splice(openInd, 1);
            // 删除掉该设备的错误验证信息
            var remainErrorData =
                (sessionStorage.getItem("canvasErrorMap") &&
                JSON.parse(sessionStorage.getItem("canvasErrorMap"))) ||
                {};
            const compError = remainErrorData[this.selectedCurrentPage][this.curComponent.id];
            if (!compError || !compError['device_' + id]) {
                // 如果没有该设备的错误信息就不需要处理
                return;
            }
            delete remainErrorData[this.selectedCurrentPage][this.curComponent.id]['device_' + id];
            sessionStorage.setItem(
                "canvasErrorMap",
                JSON.stringify(remainErrorData)
            );
            this.$store.commit("setCurErrorMap");
        },
        handleOpenChange(val) {
            this.deviceOpenKeys = val;
        },
        addDirectionClick(id) {
            this.addDeviceId = id;
            this.$refs.directivesModal.switchModalVisible(true);
        },
        directivesModalConfirm(ret) {
            let deviceInd = this.curComponent.passiveMonitorConfig.devices.findIndex(item => item.id === this.addDeviceId);
            this.curComponent.passiveMonitorConfig.devices[deviceInd].bindDirectives = [ret];
        },
        deleteDirective(d, ind) {
            const tags = this.curComponent.passiveMonitorConfig.devices[ind].bindDirectives;
            const ret = tags.filter((item) => item.temId != d.temId);
            this.curComponent.passiveMonitorConfig.devices[ind].bindDirectives = ret;
        },
        inputValChange(key, val, deviceId) {
            const validateFunc = ATTRS_VALIDATE_MAP[key] && ATTRS_VALIDATE_MAP[key].validate;
            if (validateFunc && typeof validateFunc === 'function') {
                eventBus.$emit("inputValidate", {
                    key,
                    prefixKey: 'device_' + deviceId,
                    val: val,
                    id: this.curComponent.id
                });
            }
        },
        showImagesResourceModal(key, deviceId, callId) {
            this.imageSelectDeviceId = deviceId;
            this.imageSelectCallId = callId;
            this.modalOperationKey = key;
            this.$refs.imagesResourceModal.switchDialogVisible(true);
        },
        resetImageDefaultSrc(key, deviceId, callId) {
            let ind = this.curComponent.passiveMonitorConfig.devices.findIndex(item => item.id === deviceId);
            let callsData = this.curComponent.passiveMonitorConfig.devices[ind].deviceCalls;
            let callInd = callsData.findIndex(item => item.id === callId);
            this.curComponent.passiveMonitorConfig.devices[ind].deviceCalls[callInd][key] = '';
            this.curComponent.passiveMonitorConfig.devices[ind].deviceCalls[callInd][key + 'Info'] = {};
        }
    }
};
</script>
<style lang="scss">
.item {
  .el-input-number .el-input__inner {
    text-align: left;
  }
  .comp {
    .attr-error-tip {
        left: 0px;
        top: 31px;
        line-height: normal;
    }
  }
}
.device-calls-item {
    .el-card__header {
        padding: 10px;
    }
    .el-card__body {
        padding: 10px;
    }
    .el-card + .el-card {
        margin-top: 10px;
    }
}
</style>
<style lang="scss" scoped>
.item {
    display: flex;
    flex-direction: row;
    line-height: 32px;
    margin-bottom: 15px;
    &.deviceItem {
        flex-direction: column;
    }
    .device-item {
        display: flex;
        flex-direction: row;
        line-height: 32px;
        margin-bottom: 15px;
        &.device-calls-item {
            flex-direction: column;
        }
        .call-right-btn {
            float: right;
        }
        .call-item {
            display: flex;
            flex-direction: row;
            line-height: 32px;
            margin-bottom: 15px;
        }
    }
    .delete-device-icon {
        margin-left: 10px;
        color: #3f9eff;
        cursor: pointer;
    }
    .label {
        font-size: 12px;
        color: #606266;
        flex-wrap: nowrap;
        min-width: 56px;
        padding-right: 4px;
        flex-shrink: 0;
        .add-device-btn {
            float: right;
        }
    }
    .comp {
        position: relative;
        .prefixLabel {
            margin-right: 3px;
        }
        .suffixLabel {
            margin-left: 3px;
        }
    }
}
</style>